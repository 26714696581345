<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important"
            >
              <h4 class="text-capitalize breadcrumb-title">
                Bank Approval
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8"
                      >Approval</span
                    >
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Bank</span>
                  </li>
                </ul>
              </h4>
            </div>
          </div>
          <div class="container-fluid overlay-clipped">
            <LoaderOverlay
              :active="true"
              v-if="!asyncLoader && loadingUserBankAccounts"
              :zIndex="99"
              position="absolute"
              style="height: 100%"
            />
            <no-content v-if="noData" />
            <div class="row">
              <div class="col-12" v-if="banks.length">
                <div
                  class="
                    userDatatable
                    orderDatatable
                    sellerDatatable
                    global-shadow
                    border
                    mb-30
                    py-30
                    px-sm-30 px-20
                    bg-white
                    radius-xl
                    w-100
                  "
                >
                  <div class="table-responsive">
                    <table class="table mb-0 table-borderless border-0">
                      <thead>
                        <tr class="userDatatable-header">
                          <th scope="col">
                            <span class="userDatatable-title">No</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title"
                              >Account Name</span
                            >
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title"
                              >Account Number</span
                            >
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Bank</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Status</span>
                          </th>
                          <th scope="col" class="text-right">
                            <span class="userDatatable-title">Action</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(userAccount, k) in banks" :key="k">
                          <td>
                            <div class="orderDatatable-title">
                              {{ paginationData.offset + k + 1 }}
                            </div>
                          </td>
                          <td>
                            <div class="orderDatatable-title">
                              <router-link
                                :to="{
                                  name: 'officers-profile',
                                  params: {
                                    officer: { ulid: userAccount.ulid },
                                  },
                                }"
                              >
                                {{ userAccount.name }}
                              </router-link>
                            </div>
                          </td>
                          <td>
                            <div>{{ userAccount.number }}</div>
                          </td>
                          <td>
                            <div>{{ getBankName(userAccount.bank) }}</div>
                          </td>
                          <td>
                            <div
                              v-if="userAccount.status == 1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-success tag-transparented"
                                >Approved</span
                              >
                            </div>
                            <div
                              v-if="userAccount.status == 0"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-warning tag-transparented"
                                >Pending</span
                              >
                            </div>
                            <div
                              v-if="userAccount.status == -1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-danger tag-transparented"
                                >Declined</span
                              >
                            </div>
                          </td>
                          <td class="text-right">
                            <div
                              class="slider-controller float-right w-30"
                              :class="{
                                'el-fade disabled': userAccount.status == 1,
                              }"
                              :data-toggle="
                                userAccount.status == 1 ? '' : 'modal'
                              "
                              data-target="#modal-approve-reject"
                              :id="`slider-${userAccount.atlid}`"
                            >
                              <div
                                class="
                                  custom-control custom-switch
                                  switch-primary switch-md
                                "
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="userAccount.atlid"
                                  :checked="
                                    userAccount.status == 1 ? true : false
                                  "
                                />
                                <label
                                  class="custom-control-label"
                                  :for="userAccount.atlid"
                                  @click.prevent="selectAtlid(userAccount, k)"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="modal-info-delete modal fade show"
                    id="modal-approve-reject"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-sm modal-info"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-body">
                          <div class="modal-info-body d-flex">
                            <div class="modal-info-icon warning">
                              <span data-feather="info"></span>
                            </div>
                            <div class="modal-info-text">
                              <h6>Are you sure you want to approve</h6>
                              <!-- <p>Some contents...</p> -->
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger btn-outlined btn-sm"
                            data-dismiss="modal"
                            @click="approveUserBankAccount(-1)"
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            class="btn btn-success btn-outlined btn-sm"
                            data-dismiss="modal"
                            @click="approveUserBankAccount(1)"
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- p -->
                </div>
              </div>
              <!-- ends: col-12 -->
            </div>
          </div>
          <Pagination
            :url="bankUrl"
            :size="size"
            @pageChange="pageChange"
            @loading="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from "@/vuex/helpers.js";
import Pagination from "@/components/Pagination.vue";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import NoContent from "@/components/NoContent.vue";
import { apiClient } from "@/plugins/restclient";
import { roleAttributesMap } from "@/utilities/constant";
import { mapState } from "vuex";
export default {
  components: {
    Pagination,
    LoaderOverlay,
    NoContent,
  },
  data() {
    return {
      isLoading: false,
      totalPage: 0,
      banks: [],
      bankUrl: "/v1/office/adjudicate/account/bank",
      size: 10,
      noData: false,
      selectedUserBankAccount: {},
      roleAttributesMap,
      loadingUserBankAccounts: false,
      paginationData: "",
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Attribute Verification",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...mapState(["asyncLoader"]),
    ...authComputed,
  },
  beforeMount() {
    if (this.loggedIn == false) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    getBankName(code) {
      const bankDetails = this.$store.state.banks.filter((b) => b.code == code);
      return bankDetails[0]?.name;
    },
    loading(status) {
      this.loadingUserBankAccounts = status;
    },
    pageChange(dataSet, paginationData) {
      this.banks = dataSet;
      this.paginationData = paginationData;
      if (this.banks.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    selectAtlid(userAccount, k) {
      this.selectedUserBankAccount = userAccount;
      this.selectedUserBankAccount.index = k;
    },
    approveUserBankAccount(status) {
      this.loadingUserBankAccounts = true;
      apiClient
        .patch("/v1/office/account/bank/approve", {
          aclid: this.selectedUserBankAccount.atlid,
          status,
        })
        .then(() => {
          this.loadingUserBankAccounts = false;
          if (status == 1) {
            document.getElementById(
              `${this.selectedUserBankAccount.atlid}`
            ).checked = true;
            document
              .getElementById(`slider-${this.selectedUserBankAccount.atlid}`)
              .classList.add("el-fade");
            document
              .getElementById(`slider-${this.selectedUserBankAccount.atlid}`)
              .classList.add("disabled");
          }

          // this.banks[this.selectedUserBankAccount.index].status = status
          // this.selectedUserBankAccount.status = 1
          this.selectedUserBankAccount.status = status;
          this.$set(
            this.banks,
            this.selectedUserBankAccount.index,
            this.selectedUserBankAccount
          );
        })
        .catch((err) => {
          this.loadingUserBankAccounts = false;
          this.$store.commit("ctoast", {
            type: "danger",
            message: err.response.data.message,
            timeout: 7000,
          });
        });
    },
  },
};
</script>
